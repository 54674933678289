import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { logoutUser } from '../states/authentication.slice';

export default function Logout(props) {
    const dispatch = useDispatch();

    const { navigate } = useI18next();

    useEffect(() => {
        dispatch(logoutUser());
        navigate('/');
    }, []);

    return <></>;
}
